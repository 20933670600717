<template>
  <div class="cv-contagions">
    <h2>Configuraciones</h2>
    <v-tabs
      v-model="activeTab"
      centered
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
          Sucursales
      </v-tab>
      <v-tab href="#tab-2">
          Áreas
      </v-tab>
      <v-tab href="#tab-3">
          Tipos de áreas
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item
        value="tab-1"
      >
        <v-card class="card-margin">
          <v-container>
            <v-btn color="primary" outlined @click="editOfficePanel = true"> <v-icon left>mdi-plus-circle-outline</v-icon> Crear nueva sucursal</v-btn>
          </v-container>
          <v-data-table
          :headers="headersOffice"
          :items="itemsOffice"
          :items-per-page="10">
          <!--eslint-disable-next-line-->
          <template v-slot:item.administrative_division_id="{item}">
            {{handleCityId(item.administrative_division_id)}}
          </template>
          <!--eslint-disable-next-line-->
          <template v-slot:item.actions="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="handleEditOffice(item)">
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="handleRemoveOffice(item)">
                  <v-list-item-title>Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item
        value="tab-2"
      >
        <v-card class="card-margin">
          <v-container>
            <v-btn color="primary" outlined @click="handleEditZone(null)"> <v-icon left>mdi-plus-circle-outline</v-icon> Crear nueva área</v-btn>
          </v-container>
          <v-data-table
          :headers="headersZone"
          :items="itemsZones"
          :items-per-page="10">
          <!--eslint-disable-next-line-->
          <template v-slot:item.image="{item}">
            <v-avatar color="primary" size="35px"><img src="https://images.pexels.com/photos/1170412/pexels-photo-1170412.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" :alt="`${item.name} photo`"></v-avatar>
          </template>
          <!--eslint-disable-next-line-->
          <template v-slot:item.office_id="{item}">
            {{handleOfficeId(item.office_id)}}
          </template>
          <!--eslint-disable-next-line-->
          <template v-slot:item.zone_type.name="{item}">
            {{ item.zone_type ? item.zone_type.name: '' }}
          </template>
          <!--eslint-disable-next-line-->
          <template v-slot:item.actions="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="handleEditZone(item)">
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="handleRemoveZone(item)">
                  <v-list-item-title>Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item
        value="tab-3"
      >
        <v-card class="card-margin">
          <v-container>
            <v-btn color="primary" outlined @click="editZoneTypesPanel = true"> <v-icon left>mdi-plus-circle-outline</v-icon> Crear nuevo tipo de área</v-btn>
          </v-container>
          <v-data-table
          :headers="headersZoneTypes"
          :items="zoneTypes"
          :items-per-page="10">

          <template v-slot:item.single_capacity="{item}"> 
            <v-simple-checkbox
              v-model="item.single_capacity"
              disabled
            ></v-simple-checkbox>
          </template>

          <!--eslint-disable-next-line-->
          <template v-slot:item.actions="{item}">
            <v-menu
              bottom
              left
            >

              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="handleEditZoneType(item)">
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="handleRemoveZoneType(item)">
                  <v-list-item-title>Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <cv-panel :active="editOfficePanel" @close="editOfficePanel = false, editOffice = {}">
      <template v-slot:header>
        <h2 class="talana-h2">{{editOffice.name ? editOffice.name : 'Nueva sucursal'}}</h2>
      </template>
      <v-container>
        <v-form v-model="validOffice" ref="officeForm">
          <v-row>
            <v-col cols="12">
              <v-text-field :rules="[rules.required]" v-model="editOffice.name" filled label="Nombre"></v-text-field>
              <v-select :rules="[rules.required]" v-model="editOffice.administrative_division_id" filled item-text="name" item-value="id" :items="admDivisions" label="Comuna"></v-select>
              <v-text-field :rules="[rules.required]" v-model="editOffice.address" filled label="Dirección"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <v-btn color="primary" outlined>Cancelar</v-btn>
        <v-btn color="primary" @click="handleSaveOffice" depressed>Guardar</v-btn>
      </template>
    </cv-panel>
    <cv-panel :active="editZonePanel" @close="editZonePanel = false, editZone = {}">
      <template v-slot:header>
        <h2 class="talana-h2">{{editZone.name ? editZone.name : 'Nueva área'}}</h2>
      </template>
      <v-container>
        <v-form v-model="validZone" ref="zoneForm">
          <v-row>
            <v-col cols="12">
              <v-text-field :disabled="editZoneActive" :rules="[rules.required]" v-model="editZone.name" filled label="Nombre"></v-text-field>
              <v-select :disabled="editZoneActive" :rules="[rules.required]" v-model="editZone.office_id" filled item-text="name" item-value="id" :items="itemsOffice" label="Sucursal"></v-select>
              <v-select :disabled="editZoneActive" v-on:change="checkUniqueCapacity(editZone)" :rules="[rules.required]" v-model="editZone.zone_type" filled item-text="name" item-value="id" :items="zoneTypes" label="Tipo de áreas"> </v-select>
              <v-text-field  :disabled="editZoneActive" v-show="showCapacitiesFields" :rules="[rules.required]" v-for="(stage, index) in editZone.capacities" :key="index" v-model="stage.capacity" filled :label="`Capacidad en ${stage.name ? stage.name : stages[index].name}`"></v-text-field>
              <v-checkbox :disabled="true" v-if="!showCapacitiesFields" input-value="true" label="Capacidad única"></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <v-btn color="primary" outlined>Volver</v-btn>
        <v-btn v-if="!editZoneActive" color="primary" @click="handleSaveZone" depressed>Guardar</v-btn>
      </template>
    </cv-panel>
    <cv-panel :active="editZoneTypesPanel" @close="cleanZoneType">
      <template v-slot:header>
        <h2 class="talana-h2">{{editZoneTypes.name ? editZoneTypes.name : 'Nuevo tipo de área'}}</h2>
      </template>
      <v-container>
        <v-form v-model="validZoneType" ref="zoneTypeForm">
          <v-row>
            <v-col cols="12">
              <v-text-field :disabled="editZoneTypeActive" :rules="[rules.required]" v-model="editZoneTypes.name" filled label="Nombre"></v-text-field>
              <v-checkbox :disabled="editZoneTypeActive" v-model="editZoneTypes.single_capacity" label="Capacidad única"></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <v-btn color="primary" outlined>Volver</v-btn>
        <v-btn v-if="!editZoneTypeActive" color="primary" @click="checkUpdatableZoneType" depressed>Guardar</v-btn>
      </template>
    </cv-panel>
    <v-snackbar
      v-model="msg.active"
      :color="msg.type"
      top
      right
    >
      {{ msg.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="msg.active = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="removeDialog.active"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h6">
          ¿Estás seguro de eliminar {{removeDialog.type | type}} {{removeDialog.item.name}}?
        </v-card-title>
        <v-card-text>Si lo eliminas no podrás recuperarlo luego.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="handleConfirmDelete(false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            depressed
            @click="handleConfirmDelete(true)"
          >
            Si, eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editZoneTypeDialog.active"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          ¿Estás seguro de modificar la capacidad única de este tipo de área?
        </v-card-title>
        <v-card-text>Ten en cuenta que está acción modificará la capacidad máxima de todas las áreas asociadas a este tipo de área</v-card-text>
        <v-card-text v-if="editZoneTypeDialog.conflictZones.length>0">De igual manera, se eliminarán la siguientes reservas:
        <v-list-item v-if="editZoneTypeDialog.conflictZones.length>0">
          <v-list-item-content v-for="(zone, index) in editZoneTypeDialog.conflictZones" :key="index">
            <v-list-item-title>{{zone.zone_name}}: {{zone.reservations_count}} reserva(s)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="handleConfirmUpdateZoneType(false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            depressed
            @click="handleConfirmUpdateZoneType(true)"
          >
            Si, editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CvPanel from '@/components/Panel'
export default {
  name: 'Settings',
  components: {
    CvPanel
  },
  filters: {
    type (val) {
      if (val === 'zone') return 'el área'
      if (val === 'office') return 'la sucursal'
      return val
    }
  },
  data () {
    return {
      showCapacitiesFields:true,
      editZoneActive: false,
      stages: [],
      removeDialog: {
        active: false,
        item: {},
        type: ''
      },
      editZoneTypeDialog: {
        active: false,
        conflictZones: [],
      },
      msg: {
        active: false,
        text: '',
        type: 'error'
      },
      activeTab: null,
      tabs: [{
        name: 'Sucursales'
      },
      {
        name: 'Áreas'
      }],
      headersOffice: [{
        text: 'Nombre',
        value: 'name',
      },
      {
        text: 'Comuna',
        value: 'administrative_division_id'
      },
      {
        text: 'Dirección',
        value: 'address'
      },
      {
        text: 'Acciones',
        align: 'right',
        value: 'actions'
      }],
      itemsOffice: [],
      itemsZoneTypes: [],
      headersZone: [{
        text: '',
        width:'40px',
        value: 'image',
      },
      {
        text: 'Nombre',
        value: 'name',
      },
      {
        text: 'Sucursal',
        value: 'office_id'
      },
      {
        text: 'tipo de área',
        value: 'zone_type.name'
      },
      {
        text: 'Acciones',
        align: 'right',
        value: 'actions'
      }],
      headersZoneTypes: [{
        text: 'Nombre',
        value: 'name'
      },
      {
        text: 'Capacidad única',
        value: 'single_capacity'
      },
      {
        text: 'Acciones',
        align: 'right',
        value: 'actions'
      }],
      itemsZones: [],
      admDivisions: [],
      editOfficePanel: false,
      editOffice: {},
      editZonePanel: false,
      editZone: {},
      rules: {
        required: value => !!value || 'Este campo es requerido'
      },
      validOffice: true,
      validZone: true,
      zoneTypes: null,
      editZoneTypesPanel: false,
      editZoneTypes: {
        single_capacity: false
      },
      editZoneTypeActive: false,
      validZoneType: null
    }
  },
  mounted () {
    this.gets()
  },
  methods: {
    gets () {
      this.getOffices()
      this.getAllZones()
      this.getAdmDivisions()
      this.getStages()
      this.getZoneTypes()
    },
    async getZoneTypes(){
      const {data} = await this.$store.dispatch('getZoneTypes')
      this.zoneTypes = data
    },
    async getOffices() {
      const {data} = await this.$store.dispatch('getOffices')
      this.itemsOffice = data
    },
    async getStages() {
      const {data} = await this.$store.dispatch('getStages')
      this.stages = data
    },
    async getAllZones() {
      const {data} = await this.$store.dispatch('getAllZones')
      this.itemsZones = data
    },
    async getAdmDivisions() {
      const {data} = await this.$store.dispatch('getAdmDivisions')
      this.admDivisions = data
    },
    checkUniqueCapacity (editZone){
      let zoneType = this.zoneTypes.find(zoneType => zoneType.id===editZone.zone_type)
      this.showCapacitiesFields = !zoneType.single_capacity
      editZone.capacities.forEach(stage => stage.capacity=1)      
    },
    handleEditOffice (item) {
      this.editOfficePanel = true
      this.editOffice = item
    },
    handleEditZoneType (item) {
      this.editZoneTypesPanel = true
      this.editZoneTypes = {...item}
    },
    handleRemoveOffice (item) {
      this.removeDialog = {
        active: true,
        item: item,
        type: 'office'
      }
    },
    handleRemoveZone (item) {
      this.removeDialog = {
        active: true,
        item: item,
        type: 'zone'
      }
    },
    handleRemoveZoneType (item) {
      this.removeDialog = {
        active: true,
        item: item,
        type: 'zoneType'
      }
    },
    async handleConfirmDelete (confirm) {
      if (confirm) {
        if (this.removeDialog.type === 'zone') {
          await this.$store.dispatch('deleteZone', this.removeDialog.item.id)
          this.gets()
          this.removeDialog = {
            active: false,
            item: {},
            type: ''
          }
        } else if (this.removeDialog.type === 'office') {
          await this.$store.dispatch('deleteOffice', this.removeDialog.item.id)
          this.gets()
          this.removeDialog = {
            active: false,
            item: {},
            type: ''
          }
        } else {
          await this.$store.dispatch('deleteZoneType', this.removeDialog.item.id)
          this.gets()
          this.removeDialog = {
            active: false,
            item: {},
            type: ''
          }
        }
      } else {
        this.removeDialog = {
            active: false,
            item: {},
            type: ''
          }
      }
    },
    handleEditZone (item) {
      if (item) {
        this.editZonePanel = true
        this.editZone = item
        this.editZoneActive = false
        this.showCapacitiesFields = !item.zone_type.single_capacity
      } else {
        this.showCapacitiesFields = true
        this.editZoneActive = false
        const capacities = this.stages.map(x => {
          return {
            capacity: 0,
            stage_id: x.id,
            name: x.name
          }
        })
        const zoneTypes = this.zoneTypes.map(x => {
          return {
            name: x.name,
            id: x.id
          }
        })

        this.editZonePanel = true
        this.editZone = {capacities, zoneTypes}
        
      }
    },
    handleStageActive (index) {
      this.editZone.capacities.map(x => x.active = false)
      this.editZone.capacities[index].active = true
    },
    async handleSaveZone () {
      this.$refs.zoneForm.validate()
      if (this.validZone) {
        try {
          if (this.editZone.zone_type.id){
            let zoneTypeId = this.editZone.zone_type.id
            this.editZone.zone_type = zoneTypeId
          }

          await this.$store.dispatch('saveZone', this.editZone)
          this.msg = {
            active: true,
            type: 'success',
            text: 'El área se ha guardado exitosamente'
          }
          this.editZonePanel = false
          this.getAllZones()
        } catch (e) {
          this.msg = {
            active: true,
            type: 'error',
            text: e.response.data.detail
          }
        }
      }
    },
    async handleSaveZoneType () {
      this.$refs.zoneTypeForm.validate()
      if (this.validZoneType) {
        console.log(this.editZoneTypes)
        try {
          await this.$store.dispatch('saveZoneType', this.editZoneTypes)
          this.getZoneTypes()
          this.msg = {
            active: true,
            type: 'success',
            text: 'El tipo de área se ha guardado exitosamente'
          }
          this.editZoneTypesPanel = false
        } catch (e) {
          this.msg = {
            active: false,
            type: 'error',
            text: e.response.data.detail
          }
        }
      }
    },
    async handleSaveOffice () {
      this.$refs.officeForm.validate()
      if (this.validOffice) {
        try {
          await this.$store.dispatch('saveOffice', this.editOffice)
          this.getOffices()
          this.msg = {
            active: true,
            type: 'success',
            text: 'La sucursal se ha guardado exitosamente'
          }
          this.editOfficePanel = false
        } catch (e) {
          this.msg = {
            active: true,
            type: 'error',
            text: e.response.data.detail
          }
        }
      }
    },
    handleOfficeId (id) {
      if (id) return this.itemsOffice.filter(x => x.id === id)[0].name

      return id
    },
    handleCityId (id) {
      const admDiv = this.admDivisions.filter(x => x.id === id)[0]
      if (id && admDiv) return admDiv.name

      return id
    },
    async checkUpdatableZoneType (){
      let zoneTypeId = this.editZoneTypes.id ? this.editZoneTypes.id : null
      
      if(!zoneTypeId){
        this.handleSaveZoneType()
        this.cleanZoneType()
        this.gets()
        return
      }

      let oldZoneType = this.zoneTypes.find(x => x.id === zoneTypeId) 
      if (oldZoneType.single_capacity != this.editZoneTypes.single_capacity){
          let {data} = await this.$store.dispatch("checkUpdatableZoneType", zoneTypeId)
          let conflictZones = data
          console.log(conflictZones)
          this.editZoneTypeDialog = {
            active: true,
            conflictZones: conflictZones
          }
      }else {
          this.handleSaveZoneType()
          this.cleanZoneType()
          this.gets()
        }      
    },
    handleConfirmUpdateZoneType (confirm) {
      if (confirm){
        this.handleSaveZoneType()
        this.cleanZoneType()
        this.gets()
      }
      this.editZoneTypeDialog = {
        active: false,
        conflictZones: [],
      }
    },
    cleanZoneType (){
      this.editZoneTypesPanel = false 
      this.editZoneTypes = {
        name: "",
        single_capacity: false
      }
    }
  }
}
</script>

<style>
  .card-margin {
    margin: 15px
  }

</style>
