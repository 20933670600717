import axios from 'axios'

import { API_URL_BASE, HEADERS } from './config'

export const getAdmDivisions = async function () {
  const path = `${API_URL_BASE}/api/admin/adm-divisions`
  const config = {
    url: path,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const getPeople = async function () {
  const path = `${API_URL_BASE}/api/admin/company/employees`
  const config = {
    url: path,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const getOffices = async function () {
  const path = `${API_URL_BASE}/api/admin/offices`
  const config = {
    url: path,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
}

export const postOffice = async function (payload) {
  const path = `${API_URL_BASE}/api/admin/offices/`
  const config = {
    url: path,
    method: 'POST',
    data: payload,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const putOffice = async function (payload) {
  const path = `${API_URL_BASE}/api/admin/offices/${payload.id}/`
  const config = {
    url: path,
    method: 'PUT',
    data: payload,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const deleteOffice = async function (id) {
  const path = `${API_URL_BASE}/api/admin/offices/${id}/`
  const config = {
    url: path,
    method: 'DELETE',
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const getAllZones = async function () {
  const path = `${API_URL_BASE}/api/admin/zones`
  const config = {
    url: path,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const getZones = async function (payload) {
  const path = `${API_URL_BASE}/api/admin/offices/${payload.id}/zones`
  const config = {
    url: path,
    params: payload.params,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const getStages = async function () {
  const path = `${API_URL_BASE}/api/admin/stages`
  const config = {
    url: path,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const getZoneDetail = async function (payload) {
  const path = `${API_URL_BASE}/api/admin/zone/${payload.zoneId}/reservations`
  const config = {
    url: path,
    params: payload.params,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const postZone = async function (payload) {
  const path = `${API_URL_BASE}/api/admin/zones/`
  const config = {
    url: path,
    method: 'POST',
    data: payload,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const putZone = async function (payload) {
  const path = `${API_URL_BASE}/api/admin/zones/${payload.id}/`
  const config = {
    url: path,
    method: 'PUT',
    data: payload,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
} 

export const deleteZone = async function (id) {
  const path = `${API_URL_BASE}/api/admin/zones/${id}/`
  const config = {
    url: path,
    method: 'DELETE',
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
}

export const getZoneTypes = async function () {
  const path = `${API_URL_BASE}/api/admin/zone-types`
  const config = {
    url: path,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
}

export const postZoneType = async function (payload) {
  const path = `${API_URL_BASE}/api/admin/zone-types`
  const config = {
    url: path,
    method: 'POST',
    data: payload,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
}

export const putZoneType = async function (payload) {
  const path = `${API_URL_BASE}/api/admin/zone-types/${payload.id}/`
  const config = {
    url: path,
    method: 'PUT',
    data: payload,
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
}

export const deleteZoneType = async function (id) {
  const path = `${API_URL_BASE}/api/admin/zone-types/${id}/`
  const config = {
    url: path,
    method: 'DELETE',
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
}

export const checkUpdatableZoneType = async function (id) {
  const path = `${API_URL_BASE}/api/admin/zone-types/${id}/check-updatable`
  const config = {
    url: path,
    method: 'GET',
    headers: HEADERS(),
  }
  const data = await axios(config)
  return data
}