<template>
  <transition name="fade">
    <div v-if="active || devMode" class="wrapper">
      <div :class="[{active: 'hidden'}, 'talana-overlay']" @click="closePanelOnOverlayClick ? closePanel : ''"></div>
      <transition name="slide-fade">
        <div v-if="activePanel || devMode" :class="['talana-panel', {'big': big}]">
          <div class="talana-panel-header">
            <div class="title">
              <slot name="header">
                <h2 class="talana-h2">¡Esto es un panel!</h2>
                <p>Que puede tener una descripción super fabulosa</p>
              </slot>
            </div>
            <div class="actions">
              <v-btn @click="closePanel" text icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div ref="panelBody" id="panelBody" :class="['talana-panel-body', {'with-controller': tabs || searchData}]">
            <div v-if="activePeriod" class="talana-panel-info" id="panelInfo">
              <p>Mes de proceso: {{currentPeriod.month}} de {{currentPeriod.year}}</p>
            </div>
            <div v-if="isEmpty" class="talana-panel-empty-state">
              <v-icon class="empty-icon">{{emptyState.icon}}</v-icon>
              <p>{{emptyState.text}}</p>
            </div>
            <slot>
              <div class="talana-panel-empty-state">
                <p>{{emptyState.text}}</p>
              </div>
            </slot>
          </div>
          <div class="talana-panel-footer">
            <slot name="footer" class="talana-panel-footer-right">
              <v-btn color="primary" @click="closePanel">Ok!</v-btn>
            </slot>
          </div>

        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
function handleBodyScroll ( action ) {
  if (action === 'open') {
    document.documentElement.style.overflowY = 'hidden'
    document.body.style.overflowY = 'hidden'
  } else {
    document.documentElement.style.overflowY = 'auto'
    document.body.style.overflowY = 'auto'
  }
}

export default {
  name: 'Panel',
  props: {
    closePanelOnOverlayClick: {
      type: Boolean,
      default: false
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Boolean
    },
    searchData: {
      type: Boolean
    },
    emptyState: {
      type: Object,
      default: function () {
        return {
          icon: 'mdi-heart-outline',
          text: '¡Hola! Esto es un empty panel, contiene cuatro slots, uno para el header, otro para el cuerpo y otros dos para las acciones del footer, así que rellenala como quieras ;)'
        }
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    },
    activePeriod: {
      type: Object
    },
    devMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activePanel: false,
      activeTab: 0,
      origin: 'left',
      disableTabs: false,
      containerIds: [],
      tabsIds: [],
      searcherActive: false,
      searchInput: ''
    }
  },
  computed: {
    filteredItems () {
      return this.containerIds.filter( item => {
        return item.name.toLowerCase().indexOf( this.searchInput.toLowerCase() ) > -1
      } )
    },
    currentPeriod () {
      let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      if (this.activePeriod) {
        return {
          month: months[this.activePeriod.mes - 1],
          year: this.activePeriod.ano
        }
      }

      return {}
    }
  },
  created () {
    // Bind to scroll
  },
  mounted () {
    // From testing, without a brief timeout, it won't work.
  },
  methods: {
    closePanel () {
      let self = this
      this.activePanel = false
      setTimeout( function () {
        self.$emit( 'close' )
        handleBodyScroll( 'close' )
      }, 250 )
    },
    handlePanelTab ( index ) {
      let origin = this.activeTab

      if (origin > index) {
        this.origin = 'right'
      } else {
        this.origin = 'left'
      }

      this.activeTab = index
    },
    onStartScroll () {
      this.disableTabs = true
    },
    onDoneScroll () {
      setTimeout( () => this.disableTabs = false, 50 )
    },
    getIds () {
      let ids = this.$refs.panelBody.querySelectorAll( '[id]' )
      this.tabsIds = []
      ids.forEach( ( el ) => {
        if (el.tagName === 'H4') {
          this.tabsIds.push( {
            hash: el.id,
            name: el.id.replace( /-/g, ' ' )
          } )
        }

        this.containerIds.push( {
          hash: el.id,
          name: el.id.replace( /-/g, ' ' )
        } )
      } )
    },
    handleCloseSearcher () {
      setTimeout( () => this.searcherActive = false, 100 )
    }
  },
  watch: {
    active: function ( nV ) {
      let self = this
      if (nV === true) {
        handleBodyScroll( 'open' )
        setTimeout( function () {
          self.activePanel = self.active
        }, 100 )
        setTimeout( function () {
          self.containerIds = []
          self.getIds()
        }, 200 )
      } else {
        handleBodyScroll( 'close' )
      }
    },
    searchInput: function ( nV ) {
      if (nV === null) this.searchInput = ''
    }
  }
}
</script>

<style lang="scss">
  $controller-height: 50px;
  $header-height: 80px;
  $footer-height: 70px;
  $intercom-space-height: 100px;

  h2.talana-h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;

    .talana-overlay {
      background-color: rgba(0, 0, 0, .4);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .talana-panel {
      position: fixed;
      right: 0;
      top: 0;
      width: 550px;
      height: 100vh;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 24px 38px rgba(0, 0, 0, .3);

      &.big {
        width: 900px;
      }

      &:before {
        display: block;
        content: '';
        height: 6px;
        width: 100%;
        background: var(--talana-blue);
      }

      &-header {
        height: $header-height;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #D8D8D8;

        .actions {
          width: 74px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .title {
          width: calc(100% - 74px);
          padding: 16px 24px;
          box-sizing: content-box;
          font-family: var(--font-family) !important;

          h2, h1, h3, h4 {
            margin-top: 0;
            margin-bottom: 0;
          }

          p {
            margin-top: 6px;
            font-size: 14px;
            line-height: 20px;
            color: #777;
          }
        }
      }

      .panel-controller {
        height: $controller-height;
        box-shadow: 0 2px 2px rgba(#000, .1);
        display: flex;
        padding: 0 24px;

        .talana-panel-tabs {
          list-style: none;
          width: calc(100% - 50px);
          padding-left: 0;
          margin-bottom: 0;

          span.talana-disable-tabs {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: white;
            z-index: 8;
            opacity: .2;
          }

          li {
            text-transform: capitalize;
            height: 100%;
            min-width: 80px;
            justify-content: center;
            display: inline-flex;
            align-items: center;
            padding: 0 16px;
            position: relative;
            font-size: var(--font-size-body);
            color: #757575;

            &:hover {
              cursor: pointer;
              color: var(--talana-blue);
            }

            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              height: 2px;
              width: 0;
              background-color: var(--talana-blue);
            }

            &.origin-left:before {
              left: 0;
              right: auto;
            }

            &.origin-right:before {
              right: 0;
              left: auto;
            }

            &:after {
              position: absolute;
              content: '';
              width: 8px;
              height: 8px;
              border: 8px solid transparent;
              border-bottom-color: var(--talana-blue);
              bottom: 2px;
              left: calc(50% - 8px);
              opacity: 0;
            }

            &.active {
              color: var(--talana-blue);
              font-weight: 500;

              &:before {
                transition: .5s all ease;
                width: 100%;
              }

              &:after {
                opacity: 1;
                transition: .5s all ease;
              }
            }
          }
        }

        .talana-panel-searcher {
          position: relative;

          .input-wrapper .v-input__slot {
            padding: 0 16px 14px 16px;
          }

          .v-text-field__details {
            display: none;
          }

          .v-input__slot {
            margin-bottom: 0;
          }

          .talana-searcher-list-empty-state {
            padding: 0 24px;
            color: #9e9e9e;

            p {
              margin-bottom: 0;
              text-align: center;
            }
          }

          .talana-searcher-list-wrapper {
            position: absolute;
            top: 100%;
            right: 5px;
            z-index: 999;
            list-style: none;
            width: 300px;

            ul {
              margin: 8px 0 14px 0;
              padding-left: 0;
              max-height: 150px;
              overflow-y: auto;
            }

            li {
              padding: 0 16px;
              line-height: 32px;
              font-size: 14px;
              list-style: none;
              text-transform: capitalize;

              &:hover {
                background-color: #eee;
                cursor: pointer;
              }
            }
          }
        }
      }

      &-body {
        height: calc(100% - (#{$header-height} + #{$footer-height}));
        scroll-behavior: smooth;
        overflow-y: auto;

        &.with-controller {
          height: calc(100% - (#{$header-height} + #{$footer-height} + #{$intercom-space-height} + #{$controller-height}));
        }
      }


      &-footer {
        padding: 16px 25px;
        border-top: 1px solid rgba(#A9A9A9, .4);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .v-btn {
          margin: 0 8px 0 0;

          &:last-child {
            margin: 0;
          }
        }

        > div {
          display: flex;
          align-items: center;
        }


        &-left {
          justify-content: flex-start;
        }

        &-left {
          justify-content: flex-end;
        }
      }

      &-empty-state {
        height: 100%;
        color: var(--talana-gray-light);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .empty-icon {
          font-size: 45px;
          color: var(--talana-gray-lighter);
          margin-bottom: 15px;
        }

        img {
          opacity: .4;
        }

        p {
          color: inherit;
        }
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .2s ease;
  }

  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
  {
    transform: translateX(100%);
    opacity: 0;
  }

  .v-form > .container {
    padding: 24px;
  }

  .v-form > .container > .layout > .flex {
    padding: 8px;
  }

  .v-form > .container > .layout:only-child {
    margin: -8px;
  }

  .v-form > .container > .layout:not(:only-child) {
    margin: auto -8px;
  }


  .talana-panel-info {
    position: fixed;
    top: 0;
    right: 0;
    padding: 2px 15px 2px 17px;
    background: var(--talana-blue);
    color: #fff;
    border-radius: 0 0 0 30px;

    p {
      margin-bottom: 0;
    }
  }
</style>
