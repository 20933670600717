<template>
  <div class="cv-contagions">
    <h2>Contagios</h2>
    <v-card class="mt-4">
      <a ref="downloadTrace" v-show="false"></a>
      <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10">
      <!--eslint-disable-next-line-->
          <template v-slot:item.user.image="{item}">
            <v-avatar color="primary" size="35px"><img :src="item.user.image" :alt="`${item.user.full_name} avatar`" /></v-avatar>
          </template>
          <!--eslint-disable-next-line-->
          <template v-slot:item.contagion_date="{item}">
            {{item.contagion_date | moment('DD/MM/YYYY')}}
          </template>
          <!--eslint-disable-next-line-->
          <template v-slot:item.actions="{item}">
            <v-tooltip left>
              <template v-slot:activator="{attrs, on}">
                <v-btn v-on="on" :loading="loadingItem === item.id" v-bind="attrs" @click="getTraceByContagion(item.id, item.contagion_date)" text icon>
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar trazas</span>
            </v-tooltip>
          </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Contagions',
  data () {
    return {
      headers: [
      {
        text: '',
        width:'40px',
        value: 'user.image',
      },
      {
        text: 'Nombre',
        value: 'user.full_name'
      },
      {
        text: 'Fecha',
        value: 'contagion_date',
      },
      {
        text: 'Acciones',
        value: 'actions',
        width: '80px'
      }],
      items: [],
      loadingItem: null
    }
  },
  created () {
    this.gets()
  },
  methods: {
    gets () {
      this.getContagions()
    },
    async getContagions () {
      const {data} = await this.$store.dispatch('getContagions')
      this.items = data
    },
    async getTraceByContagion(id = null, date = null) {
      this.loadingItem = id
      const {data} = await this.$store.dispatch('getTraceByContagion', id)
      this.loadingItem = null
      const url = window.URL.createObjectURL(new Blob([data]))
      this.$refs.downloadTrace.href = url
      this.$refs.downloadTrace.download = (`Reporte de Trazas - ${this.$moment(date).format('DD/MM/YYYY')}.xlsx`)
      this.$refs.downloadTrace.click()
    }
  }
}
</script>

<style>

</style>