import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#2222BF',
        secondary: '#57FBDB',
        accent: '#2222BF',
        error: '#E90019',
        warning: '#FFD000',
        info: '#0081FC',
        success: '#00AF7D',
      },
      dark: {
        primary: '#2222BF',
        secondary: '#57FBDB',
        accent: '#2222BF',
        error: '#E90019',
        warning: '#FFD000',
        info: '#0081FC',
        success: '#00AF7D',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  }
});
