<template>
  <div class="cv-home">
    <h2>Estado actual de los espacios</h2>
    <v-tabs
      v-model="tab"
      centered
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab v-for="(office, index) in offices" :key="index" :href="`#office-${office.id}`">
        {{office.name}}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(office, index) in offices"
        :key="index"
        :value="'office-' + office.id"
      >
        <office-group :filters="true" :id="office.id">
        </office-group>
      </v-tab-item>
    </v-tabs-items>
    
  </div>
</template>

<script>
import OfficeGroup from '@/components/OfficeGroup'
import { mapState } from 'vuex'

export default {
  name: 'DashboardHome',
  components: {
    OfficeGroup
  },
  data () {
    return {
      tab: null,
      offices: [],
    }
  },
  computed: {
    ...mapState({
      act: state => state.app.act
    })
  },
  mounted () {
    this.gets()
  },
  methods: {
    gets() {
      this.getOffices()
    },
    async getOffices() {
      const { data } = await this.$store.dispatch('getOffices')
      this.offices = data
      this.$store.commit('SET_ACT', false)
    }
  },
  watch: {
    act: {
      handler: function () {
        if (this.act) this.getOffices()
      }
    }
  }
}
</script>

<style lang="scss">
.cv-home {
}
</style>