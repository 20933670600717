<template>
  <div id="app">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  }),
  created () {
    document.title = 'Híbrido'
    this.handleTokens()
  },
  mounted () {
    this.handleAuth()
  },
  methods: {
    handleTokens () {
      if (this.$route.query.apikey) {
        localStorage.setItem('apikey', this.$route.query.apikey)
      }
      if (this.$route.query.jwt_token) {
        localStorage.setItem('jwt', this.$route.query.jwt_token)
      }
    },
    handleAuth () {
      // eslint-disable-next-line 
      if (localStorage.getItem('apikey') === null || localStorage.getItem('jwt') === null) window.location.href="https://talana.com/es/remuneraciones/login-vue#/"
    }
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,500;0,700;1,400&display=swap');

body, .v-application {
  font-family: 'Ubuntu', sans-serif !important;
}

.v-text-field > .v-input__control > .v-input__slot::before, .v-text-field > .v-input__control > .v-input__slot::after {
  display: none;
}

.v-text-field--filled {
  border-radius: 4px;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #F1F1F8;
}

.v-btn {
  letter-spacing: 0;
}

.v-btn__content {
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
}

.v-tabs-bar__content {
  border-bottom: 1px solid #cfcfcf;
}

.v-tab {
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.cv-btn--full {
  width: 100%;
}

h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
}

.empty-state {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  color: #a3a3a3;
}
</style>
