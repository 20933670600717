import axios from 'axios'

import { API_URL_BASE, HEADERS } from './config'


export const getContagions = async function () {
  const path = `${API_URL_BASE}/api/admin/contagions`
  const config = {
    url: path,
    headers: HEADERS()
  }
  const data = await axios(config)
  return data
} 

export const getTraceabilityXLSX = async function (params) {
  const path = `${API_URL_BASE}/api/admin/traceability/xlsx`
  const config = {
    url: path,
    params,
    responseType: 'blob',
    headers: HEADERS()
  }
  const data = await axios(config)
  return data
} 

export const getTraceabilityGraph = async function (params) {
  const path = `${API_URL_BASE}/api/admin/traceability/graph`
  const config = {
    url: path,
    params,
    responseType: 'blob',
    headers: HEADERS()
  }
  const data = await axios(config)
  return data
} 

export const getTraceByContagion = async function (id) {
  const path = `${API_URL_BASE}/api/admin/contagions/${id}/trace`
  const config = {
    url: path,
    responseType: 'blob',
    headers: HEADERS()
  }
  const data = await axios(config)
  return data
} 