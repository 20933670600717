import InTone from '@/assets/notification_in.mp3' 
import OutTone from '@/assets/notification_out.mp3' 

export default {
  data: () => ({
    notificationText: "",
    notificationType: "",
    notificationColor: "",
    notificationAvatar: null,
    notificationQueue: [],
    notification: false,
    tone: null,
  }),
  computed: {
    hasNotificationsPending() {
      return this.notificationQueue.length > 0
    }
  },
  watch: {
    notification() {
      if(!this.notification && this.hasNotificationsPending) {
        const current = this.notificationQueue.shift()
        this.notificationText = `${current.msg.full_name} acaba de hacer ${current.type === 'check_in' ? 'check in' : 'check out'}`
        this.notificationAvatar = current.msg.image ? current.msg.image : null 
        this.notificationType = current.type
        this.notificationColor = current.color
        this.$nextTick(() => {
          this.notification = true
          this.handleTone(current.type)
        })
      }
    }
  },
  methods: {
    addNotification(notification) {
      this.notificationQueue.push(notification)
      if(!this.notification) {
        const current = this.notificationQueue.shift()
        this.notificationText = `${current.msg.full_name} acaba de hacer ${current.type === 'check_in' ? 'check in' : 'check out'}`
        this.notificationAvatar = current.msg.image ? current.msg.image : null 
        this.notificationType = current.type
        this.notificationColor = current.color
        this.notification = true
        this.handleTone(current.type)
      }
    },
    handleTone (type) {
      switch (type) {
        case 'check_in':
          this.tone = new Audio(InTone)
          break
        case 'check_out':
          this.tone = new Audio(OutTone)
          break
        default:
          this.tone = new Audio(InTone)
          break  
      }

      this.tone.play()
    }
  }
}