<template>
  <div class="cv-aside">
    <div class="cv-aside__user">
      <v-avatar color="primary" size="50px">
        <img :src="me.image" alt="Tundisi">
      </v-avatar>
      <div class="cv-aside__user__text">
        <h4 class="cv-aside__user__name">Hola, {{me.full_name}}</h4>
        <v-btn @click="handleLogOut" small text color="primary">Cerrar sesión</v-btn>
      </div>
    </div>
    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-menu</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Aforo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-group
        color="primary"
      >
        <v-list-item
          :to="{name: 'dashboardHome'}"
          exact
        >
        <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="showActNotification">
            <v-icon small color="error">mdi-circle</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          :to="{name: 'contagions'}"
          exact
        >
        <v-list-item-icon>
            <v-icon>mdi-virus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contagios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{name: 'settings'}"
          exact
        >
        <v-list-item-icon>
            <v-icon>mdi-tune</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configuración</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item
        @click="handleTraceabilityModal('open')"
        >
        <v-list-item-icon>
            <v-icon>mdi-download-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reporte de Aforo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </v-list-item-group>
    </v-list>
    <v-dialog
      v-model="showTraceabilityModal"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          <h2>Descargar reporte de Aforo</h2>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field prepend-inner-icon="mdi-calendar" v-model="traceabilityDate" type="date" label="Fecha" filled></v-text-field>
              </v-col>
              <!--v-col cols="12" md="6">
                <v-select :items="people.employees" item-text="full_name" item-value="id" v-model="traceabilityUser" type="date" label="Persona" filled></v-select>
              </v-col-->
            </v-row>
          </v-form>
          <a v-show="false" ref="downloadLink"></a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="handleTraceabilityModal('close')"
          >
            Cancelar
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                depressed
                v-bind="attrs"
                v-on="on"
                class="ml-3"
              >
                Generar <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <!--v-list-item @click="getTraceability('PNG')">
                <v-list-item-title>Gráfico de trazabilidad (.PNG)</v-list-item-title>
              </v-list-item-->
              <v-list-item @click="getTraceability('xlsx', true)">
                <v-list-item-title>Reporte histórico (.xlsx)</v-list-item-title>
              </v-list-item>
              <v-list-item @click="getTraceability('xlsx')">
                <v-list-item-title>Reporte día seleccionado (.xlsx)</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Aside',
  data () {
    return {
      active: true,
      showTraceabilityModal: false,
      traceabilityDate: this.$moment().format('YYYY-MM-DD'),
      loadingTraceability: false,
      valid: true,
      traceabilityUser: null,
    }
  },
  computed: {
    ...mapState({
      me: state => state.login.me,
      act: state => state.app.act,
      people: state => state.reservations.people
    }),
    showActNotification () {
      if (this.$route.name !== 'dashboardHome' && this.act) return true

      return false
    }
  },
  methods: {
    handleLogOut () {
      localStorage.removeItem('apikey')
      localStorage.removeItem('jwt')
      window.location.href = "https://talana.com/es/"
    },
    async getTraceability (type, historical=null) {
      this.$refs.form.validate()
      if (this.valid) {
        if (type === 'xlsx') {
          this.loadingTraceability = true
          const {data} = await this.$store.dispatch('getTraceabilityXLSX', {reserved_date: this.traceabilityDate, employee_id: this.traceabilityUser, historical: historical})
          const url = window.URL.createObjectURL(new Blob([data]))
          this.$refs.downloadLink.href = url
          this.$refs.downloadLink.download = (`Reporte de trazabilidad - ${this.traceabilityDate}.xlsx`)
          this.$refs.downloadLink.click()
          this.traceabilityDate = null
          this.loadingTraceability = false
          this.showTraceabilityModal = false
        } else {
          const {data} = await this.$store.dispatch('getTraceabilityGraph', {since_date: this.traceabilityDate, employee_id: this.traceabilityUser})
          const url = window.URL.createObjectURL(new Blob([data]))
          this.$refs.downloadLink.href = url
          this.$refs.downloadLink.download = (`Gráfico de trazabilidad - ${this.traceabilityDate}.png`)
          this.$refs.downloadLink.click()
          this.traceabilityDate = null
          this.loadingTraceability = false
          this.showTraceabilityModal = false
        }
      }
    },
    handleTraceabilityModal (action) {
      if (action === 'close') {
        this.traceabilityDate = null
        this.loadingTraceability = false
        this.showTraceabilityModal = false
      } else {
        this.showTraceabilityModal = true
      }
    } 
  }
}
</script>

<style lang="scss">
.cv-aside {
  position: fixed;
  top: 0;
  bottom: 48px;
  left: 0;
  width: 250px;
  padding: 20px 7px;

  &__user {
    display: flex;

    &__text {
      margin-left: 15px;

      h4 {
        margin-left: 15px;
      }
    }
  }

  ul {
    list-style: none;
  }
}
</style>
