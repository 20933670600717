const state = {
  panelDetailIsActive: false,
  panelDetail: {},
  covidAlert: false,
  act: false,
}

const mutations = {
  SET_PANEL_DETAIL_ACTIVE (state, payload) {
    state.panelDetailIsActive = payload
  },
  SET_PANEL_DETAIL (state, payload) {
    state.panelDetail = payload
  },
  SET_COVID_ALERT (state, payload) {
    state.covidAlert = payload
  },
  SET_ACT (state, payload) {
    state.act = payload
  }
}

const actions = {
}

export default {
  state,
  mutations,
  actions
}