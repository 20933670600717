import {
  getPeople,
  getAdmDivisions,
  getOffices,
  getAllZones,
  getZones,
  getZoneDetail,
  postOffice,
  postZone,
  putOffice,
  putZone,
  deleteOffice,
  deleteZone,
  getStages,
  getZoneTypes,
  postZoneType,
  putZoneType,
  deleteZoneType,
  checkUpdatableZoneType
} from '../../client/reservations.client'

const state = {
  people: []
}

const mutations = {
  SET_PEOPLE (state, payload) {
    state.people = payload
  }
}

const actions = {
  async getPeople () {
    const data = await getPeople()
    return data
  },
  async getAdmDivisions () {
    const data = await getAdmDivisions()
    return data
  },
  async getOffices () {
    const data = await getOffices()
    return data
  },
  async getAllZones () {
    const data = await getAllZones()
    return data
  },
  async getStages () {
    const data = await getStages()
    return data
  },
  async getZones (_, payload) {
    const data = await getZones(payload)
    return data
  },
  async getZoneDetail (_, payload) {
    const data = await getZoneDetail(payload)
    return data
  },
  async getZoneTypes() {
    const data = await getZoneTypes()
    return data
  },
  async saveZone (_, payload) {
    if (payload.id) {
      const data = await putZone(payload)
      return data
    } else {
      const data = await postZone(payload)
      return data
    }
  },
  async saveOffice (_, payload) {
    if (payload.id) {
      const data = await putOffice(payload)
      return data
    } else {
      const data = await postOffice(payload)
      return data
    }
  },
  async saveZoneType (_, payload) {
    if (payload.id) {
      const data = await putZoneType(payload)
      return data
    } else {
      const data = await postZoneType(payload)
      return data
    }
  },
  async deleteZone (_, id) {
    const data = await deleteZone(id)
    return data
  },
  async deleteOffice (_, id) {
    const data = await deleteOffice(id)
    return data
  },
  async deleteZoneType (_, id){
    const data = await deleteZoneType(id)
    return data
  },
  async checkUpdatableZoneType (_, id){
    const data = await checkUpdatableZoneType(id)
    return data
  }
}

export default {
  state,
  mutations,
  actions
}