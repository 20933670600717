<template>
  <div class="cv-office__group">
    <v-row v-if="filters">
      <v-col cols="12">
        <div class="cv-office__filters">
          <v-select class="mr-4 mt-4" filled v-model="workTime" :items="workTimes" item-text="name" item-value="code" label="Jornada"></v-select>
          <v-text-field class="mr-4 mt-4" filled v-model="date" label="Día" type="date"></v-text-field>
          <v-btn @click="getOfficeGroup" large color="primary">Filtrar</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="areas.length">
      <v-col v-for="(area, index) in areas" :key="index" cols="12" lg="4" md="6" >
        <div class="cv-office__item">
          <div class="cv-office__item__title">
            <h3>{{area.name}}</h3>
            <v-avatar color="primary" size="34px"><img src="https://images.pexels.com/photos/1170412/pexels-photo-1170412.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" /></v-avatar>
          </div>
          <div v-if="(area.reserved===0 && area.available===1) || (area.reserved===1 && area.available===0)">
            <h2>Zona con capacidad única:</h2>
            <h3 v-if="area.reserved===0">Disponible</h3>
            <h3 v-else>No disponible</h3>
          </div>
          <div class="cv-office__item__graph" v-else>
            <h2>{{area.fullness}}% <small>de la zona disponible</small></h2> 
            <div class="cv-office__item__graph__bar">
              <div :style="{width: `${area.fullness}%`}" class="cv-office__item__graph__innerbar"></div>
            </div>
            <v-icon color="#46C089" left small>mdi-circle</v-icon> {{area.reserved}} reservas
            <v-icon left small>mdi-circle</v-icon> {{area.available}} disponibles
          </div>
          <v-btn class="cv-btn--full" color="primary" @click="handlePanelDetail(area)" depressed>Ver detalle</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <div class="empty-state">
          <v-icon large color="#a3a3a3">mdi-map-marker-outline</v-icon>
          No existen áreas asociadas a esta sucursal
          <v-btn :to="{name: 'settings'}" color="primary" outlined>Crea áreas</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'OfficeGroup',
  props: {
    id: {
      type: Number,
    },
    filters: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      workTimes: [{
        name: 'Todo el día',
        code: 'A'
      },
      {
        name: 'Mañana',
        code: 'M'
      },
      {
        name: 'Tarde',
        code: 'N'
      }],
      areas: [],
      workTime: '',
      date: '',
    }
  },
  computed: {
    ...mapState({
      act: state => state.app.act
    })
  },
  created () {
    this.workTime = 'A'
    this.date = this.$moment().format('YYYY-MM-DD')
  },
  mounted () {
    this.getOfficeGroup()
  },
  methods: {
    async getOfficeGroup () {
      if (this.id) {
        const payload = {
          id: this.id
        }
        if (this.workTime || this.date) {
          payload.params = {
            reserved_date: this.date,
            reserved_type: this.workTime,
          }
        }


        const { data } = await this.$store.dispatch('getZones', payload)
        this.areas = data
      }
    },
    async handlePanelDetail(item) {
      const today = this.$moment().format('YYYY-MM-DD')
      const payload = {
        id: item.office.id, 
        zoneId: item.id,
        params: {
          reserved_date: this.date ? this.date : today,
          reserved_type: this.workTime ? this.workTime : 'A',
        }
      }
      const {data} = await this.$store.dispatch('getZoneDetail', payload)
      const panelDetail = {
        ...item,
        reservations: data
      }
      this.$store.commit('SET_PANEL_DETAIL', panelDetail)
      this.$store.commit('SET_PANEL_DETAIL_ACTIVE', true)
    }
  },
  watch: {
    act: {
      handler: function () {
        if (this.act) this.getOfficeGroup()
      }
    }
  }
}
</script>

<style lang="scss">
.cv-office {
  &__group {
    min-height: calc(100vh - 120px);
  }

  &__item {
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(0, 49, 61, 0.222165);
    border-radius: 6px;
    margin: 15px 10px;

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;
      border-bottom: 1px solid #cfcfcf;
    }

    &__graph{
      margin: 5px 0 20px;
      &__bar {
        width: 100%;
        height: 10px;
        border-radius: 30px;
        background-color: #9E9E9E;
        position: relative;
      }

      &__innerbar {
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;
        border-radius: 30px;
        background-color: #46C089;
      }
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
}
</style>