import axios from 'axios'

import { API_URL_BASE, HEADERS } from './config'


export const login = async function (payload) {
  const path = `${API_URL_BASE}/api/login/`
  const config = {
    url: path,
    method: 'post',
    data: payload
  }
  const data = await axios(config)
  return data
} 

export const getMe = async function (payload) {
  const path = `${API_URL_BASE}/api/user/me`
  const config = {
    url: path,
    data: payload,
    headers: HEADERS()
  }
  const data = await axios(config)
  return data
} 