<template>
  <div class="cv-reservations">
    <h2>Reservaciones</h2>
    <v-tabs
      v-model="tab"
      centered
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab v-for="(office, index) in offices" :key="index" :href="`#office-${office.id}`">
        <v-badge
          color="error"
          :content="office.occupants ? office.occupants : '0'"
        >
          {{office.name}}
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(office, index) in offices"
        :key="index"
        :value="'office-' + office.id"
      >
        <office-group :id="office.id" :filters="true">
        </office-group>
      </v-tab-item>
    </v-tabs-items>
    
  </div>
</template>

<script>
import OfficeGroup from '@/components/OfficeGroup'

export default {
  name: 'DashboardHome',
  components: {
    OfficeGroup
  },
  data () {
    return {
      tab: null,
      offices: [{
        name: 'Costanera',
        id: 1,
        occupants: 3
      },
      {
        name: 'Santiago centro',
        id: 2,
        occupants: 14
      },
      {
        name: 'Maipú',
        id: 3,
        occupants: 0
      }],
    }
  },
  mounted () {
    this.gets()
  },
  methods: {
    gets() {
      this.getOffices()
    },
    async getOffices() {
      const { data } = await this.$store.dispatch('getOffices')
      this.offices = data
    }
  }
}
</script>

<style lang="scss">
.cv-home {
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
  }
}
</style>