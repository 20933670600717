import Vue from 'vue'
import Router from 'vue-router'

import Login from './views/Login.vue'
import Dashboard from './views/Dashboard/index.vue'
import DashboardHome from './views/Dashboard/Home.vue'
import Reservations from './views/Dashboard/Reservations.vue'
import Contagions from './views/Dashboard/Contagions.vue'
import Settings from './views/Dashboard/Settings.vue'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login/',
      name: 'login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/dashboard/',
      name: 'dashboard',
      component: Dashboard,
      children: [{
        path: '',
        name: 'dashboardHome',
        component: DashboardHome,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reservations',
        name: 'reservations',
        component: Reservations,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'contagions',
        name: 'contagions',
        component: Contagions,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        meta: {
          requiresAuth: true
        }
      }]
    },
  ]
})

/* router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (localStorage.getItem('apikey') == null || localStorage.getItem('jwt') == null) {
      next({
          path: '/login',
          params: { nextUrl: to.fullPath }
      })
    } else {
      next({
        path: '/dashboard',
        params: { nextUrl: to.fullPath }
      })
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('apikey') == null || localStorage.getItem('jwt') == null) {
      next({
          path: '/login',
          params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('apikey') == null || localStorage.getItem('jwt') == null) {
      next()
    }
    else{
      next()
    }
  } else {
    next()
  }
}) */

export default router