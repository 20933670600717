<template>
  <div class="cv-login">
    <img class="cv-login__bg" src="@/assets/green_bg.svg" alt="">
    <div class="cv-login__card">
      <img src="@/assets/login_top.svg" alt="" class="cv-login__card__decor cv-login__card__decor--top">
      <img src="@/assets/login_bottom.svg" alt="" class="cv-login__card__decor cv-login__card__decor--bottom">
      <v-container>
        <h3>Para iniciar sesión introduce tu nombre de usuario, email o RUT (sin puntos y guión).</h3>
        <v-text-field filled label="Usuario" v-model="instance.username"></v-text-field>
        <v-text-field filled label="Contraseña" type="password" v-model="instance.password"></v-text-field>

        <v-btn depressed :loading="loading" color="primary" @click="login">Iniciar sesión</v-btn>
      </v-container>
    </div>
    <v-snackbar
      v-model="msg.active"
      color="error"
    >
      {{ msg.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="msg.active = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>  
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      instance: {
        username: '',
        password: ''
      },
      msg: {
        active: false,
        text: ''
      },
      loading: false,
    }
  },
  methods: {
    async login () {
      try {
        this.loading = true
        const {data} = await this.$store.dispatch('login', this.instance)
        localStorage.setItem('key', data.key)
        this.loading = false
        this.$router.push({name: 'dashboardHome'})
      } catch (e) {
        this.loading = false
        this.msg = {
          active: true,
          text: e.response.data.detail
        }
      }
    }
  }
}
</script>

<style lang="scss">
.cv-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 0;
  }

  &__card {
    width: 400px;
    height: 453px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
    z-index: 1;

    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      margin: 66px 30px;
      text-align: center;
    }

    &__decor {
      position: absolute;

      &--top {
        top: -32px;
        right: -24px;
      }
      &--bottom {
        bottom: -48px;
        left: -24px;
      }
    }
  }
}
</style>