import {
  getContagions,
  getTraceabilityXLSX,
  getTraceabilityGraph,
  getTraceByContagion
} from '../../client/contagions.client'

const state = {
  contagion: {}
}

const mutations = {
  SET_CONTAGION (state, payload) {
    state.contagion = payload
  }
}

const actions = {
  async getContagions () {
    const data = await getContagions()
    return data
  },
  async getTraceabilityXLSX (_, params) {
    const data = await getTraceabilityXLSX(params)
    return data
  },
  async getTraceabilityGraph (_, params) {
    const data = await getTraceabilityGraph(params)
    return data
  },  
  async getTraceByContagion (_, id = null) {
    const data = await getTraceByContagion(id)
    return data
  }  
}

export default {
  state,
  mutations,
  actions
}