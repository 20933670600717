<template>
  <div class="cv-alert">
    
    <div class="cv-alert__wrapper white--text">
      <v-btn @click="closeAlert" icon text dark class="cv-alert__close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <lottie-vue-player 
        :src="`https://assets8.lottiefiles.com/packages/lf20_ntnh0s55.json`"
        :theme="options.theme"
        :player-size="options.playerSize"
        :player-controls="false"
        style="width: 100%; height:150px">
        </lottie-vue-player>
        <h2>Nueva alerta de contagio</h2>
        <p>{{contagion.user.full_name}} ha reportado estar contagiado desde el día {{contagion.contagion_date | moment('DD/MM/YYYY')}}</p>
        <v-btn @click="goToContagions" color="white" outlined>Ver listado de contagios</v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              dark
              outlined
              v-bind="attrs"
              v-on="on"
              class="ml-3"
            >
              Generar <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="getTraceability('PNG')">
              <v-list-item-title>Gráfico de trazabilidad (.PNG)</v-list-item-title>
            </v-list-item>
            <v-list-item @click="getTraceability('xlsx')">
              <v-list-item-title>Reporte histórico (.xlsx)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <a v-show="false" ref="downloadLink"></a>
        <audio preload="auto" ref="audio" src="@/assets/emergency.mp3"></audio>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'CovidAlert',
  data() {
    return {
      options: {
          minimizable: false,
          playerSize: "standard",
          backgroundColor: '#fff',
          backgroundStyle: 'color',
          theme: {
              controlsView: "standard",
              active: "light",
              light: {
                  color: '#3D4852',
                  backgroundColor: '#fff',
                  opacity: '0.7',
              },
              dark: {
                  color: '#fff',
                  backgroundColor: '#202020',
                  opacity: '0.7',
              }
          }
      },
    }
  },
  computed: {
    ...mapState({
      contagion: state => state.contagions.contagion
    })
  },
  mounted () {
    this.playSound()
  },
  beforeDestroy () {
    this.stopSound()
    this.$store.dispatch('SET_CONTAGION', {})
  },
  methods: {
    playSound () {
      this.$refs.audio.volume = 0.01
      this.$refs.audio.play();
    },
    stopSound () {
      if (this.$refs.audio) this.$refs.audio.stop()
    },
    closeAlert () {
      this.$store.commit('SET_COVID_ALERT', false)
    },
    goToContagions () {
      this.closeAlert()
      this.$router.push({name: 'contagions'})
    },
    async getTraceability (type) {
      const contagionDate = this.$moment(this.contagion.contagion_date).format('YYYY-MM-DD')
      if (type === 'xlsx') {
        const {data} = await this.$store.dispatch('getTraceabilityXLSX', {reserved_date: contagionDate, employee_id: this.contagion.user.id})
        const url = window.URL.createObjectURL(new Blob([data]))
        this.$refs.downloadLink.href = url
        this.$refs.downloadLink.download = (`Reporte de trazabilidad - ${contagionDate}.xlsx`)
        this.$refs.downloadLink.click()
        this.traceabilityDate = null
      } else {
        const {data} = await this.$store.dispatch('getTraceabilityGraph', {since_date: contagionDate, employee_id: this.contagion.user.id})
        const url = window.URL.createObjectURL(new Blob([data]))
        this.$refs.downloadLink.href = url
        this.$refs.downloadLink.download = (`Gráfico de trazabilidad - ${contagionDate}.png`)
        this.$refs.downloadLink.click()
        this.traceabilityDate = null
      }
    },
  }
}
</script>

<style lang="scss">
.cv-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 0, 0, 0.219);
  z-index: 9999;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__close {
    position: absolute;
    top: 5px;
    right: 10px
  }

  &__wrapper {
    position: relative;
    background-color: red;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    box-shadow: 0px 1px 10px rgba(0, 49, 61, 0.222165);

    h2 {
      margin-bottom: 15px;
    }
  } 
}
</style>