import {
  login,
  getMe
} from '../../client/login.client'

const state = {
  me: {}
}

const mutations = {
  SET_ME (state, payload) {
    state.me = payload
  }
}

const actions = {
  async login (_, payload) {
    const data = await login(payload)
    return data
  }, 
  async getMe () {
    const data = await getMe()
    return data
  } 
}

export default {
  state,
  mutations,
  actions
}