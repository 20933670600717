// eslint-disable-next-line
const MOCK_URL_BASE = 'http://localhost:3100'
// eslint-disable-next-line
const API_LOCAL_URL_BASE = window.location.host.includes('localhost')
  ? 'http://localhost:8000/hybrid-api'
  : `https://api.${window.location.host.match(/((?<=hybrid\.).*)/g)[0]}/hybrid-api`


export const API_URL_BASE = API_LOCAL_URL_BASE

export const HEADERS = () => {
  const APIKEY = localStorage.getItem('apikey')
  const JWT = localStorage.getItem('jwt')

  return {
    'x-talana-jwt': JWT,
    apikey: APIKEY
  }
}