import Vue from 'vue'
import Vuex from 'vuex'

import login from './modules/login'
import app from './modules/app'
import reservations from './modules/reservations'
import contagions from './modules/contagions'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true,
  modules: {
    login,
    app,
    reservations,
    contagions
  }
})

export default store