var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cv-contagions"},[_c('h2',[_vm._v("Contagios")]),_c('v-card',{staticClass:"mt-4"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"downloadTrace"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10},scopedSlots:_vm._u([{key:"item.user.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"primary","size":"35px"}},[_c('img',{attrs:{"src":item.user.image,"alt":((item.user.full_name) + " avatar")}})])]}},{key:"item.contagion_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.contagion_date,'DD/MM/YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loadingItem === item.id,"text":"","icon":""},on:{"click":function($event){return _vm.getTraceByContagion(item.id, item.contagion_date)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar trazas")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }