<template>
  <div class="cv-dashboard">
    <Aside />
    <covid-alert v-if="covidAlert" />
    <div class="cv-wrapper">
      <router-view></router-view>
      <cv-panel :active="panelDetailIsActive" @close="handleClosePanel">
        <template v-slot:header>
          <h2 class="talana-h2">{{panelDetail.name}}</h2>
          <p>{{panelDetail.date ? panelDetail.date : ''}}</p>
        </template>
        <v-container>
          <div class="cv-area__img" :style="{backgroundImage: `url(https://images.pexels.com/photos/1170412/pexels-photo-1170412.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)`}"></div>
          <div v-if="(panelDetail.reserved===0 && panelDetail.available===1) || (panelDetail.reserved===1 && panelDetail.available===0)">
            <h2>Zona con capacidad única:</h2>
            <h3 v-if="panelDetail.reserved===0">Disponible</h3>
            <h3 v-else>No disponible</h3>
          </div>
          <div class="cv-office__item__graph" v-else>
            <h2>{{panelDetail.fullness}}% <small>de la zona ocupada</small></h2> 
            <div class="cv-office__item__graph__bar">
              <div :style="{width: `${panelDetail.fullness}%`}" class="cv-office__item__graph__innerbar"></div>
            </div>
            <v-icon color="#46C089" left small>mdi-circle</v-icon> {{panelDetail.reserved}} reservas
            <v-icon left small>mdi-circle</v-icon> {{panelDetail.available}} disponibles
          </div>

          <v-list subheader>
            <v-subheader v-if="panelDetail.reservations && panelDetail.reservations.length">Han reservado</v-subheader>

            <v-list-item
              v-for="(reservation, index) in panelDetail.reservations"
              :key="index"
            >
              <v-list-item-avatar>
                <v-img
                  :alt="`${reservation.user.full_name} avatar`"
                  :src="reservation.user.image"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="reservation.user.full_name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  v-if="reservation.reserved_type === 'A'"
                  color="primary"
                >
                  Todo el día
                </v-chip>
                <v-chip
                  v-else-if="reservation.reserved_type === 'M'"
                  color="warning"
                >
                  Mañana
                </v-chip>
                <v-chip
                  v-else
                  color="success"
                >
                  Tarde
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-container>
      </cv-panel>
    </div>
    <v-snackbar
      v-model="notification"
      top
      right
      text
      :color="notificationColor"
    >
      <v-avatar class="mr-2" size="30px"><img :src="notificationAvatar" alt=""></v-avatar>
      {{ notificationText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          icon
          v-bind="attrs"
          @click="notification = false"
          :color="notificationColor"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-footer color="#F1F1F8" padless>
      <v-col
        class="text-center primary--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Made with ❤️ by Talana</strong>
      </v-col>
  </v-footer>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SnackbarNotificationQueue from '@/mixins/SnackbarNotificationQeue'

import Aside from '@/components/Aside'
import CovidAlert from '@/components/CovidAlert'
import CvPanel from '@/components/Panel'
export default {
  name: 'Dashboard',
  mixins: [
    SnackbarNotificationQueue
  ],
  components: {
    Aside,
    CvPanel,
    CovidAlert
  },
  data () {
    return {
      connection: null
    }
  },
  computed: {
    ...mapState({
      me: state => state.login.me,
      panelDetailIsActive: state => state.app.panelDetailIsActive,
      covidAlert: state => state.app.covidAlert,
      panelDetail: state => state.app.panelDetail
    })
  },
  created () {
    this.gets()
    this.webSocket()
  },
  methods: {
    gets () {
      this.getMe()
      this.getPeople()
    },
    async getMe() {
      const {data} = await this.$store.dispatch('getMe')
      this.$store.commit('SET_ME', data)
    },
    async getPeople() {
      const {data} = await this.$store.dispatch('getPeople')
      this.$store.commit('SET_PEOPLE', data)
    },
    webSocket () {
      const self = this

      const API_WEB_SOCKET = process.env.NODE_ENV === 'production' ? 'wss://api.talana.com/hybrid-api/ws/' : 'ws://localhost:8000/ws/'
      console.log("Starting connection to WebSocket Server")
      this.connection = new WebSocket(API_WEB_SOCKET)

      this.connection.onmessage = function(event) {
        
        const eventParse = JSON.parse(event.data)
        console.log(eventParse);
        if (eventParse.type === 'check_in' || eventParse.type === 'check_out') {
          self.add(eventParse)
          self.$store.commit('SET_ACT', true)
        }
        if (eventParse.type === 'reservation_updated') {
          self.$store.commit('SET_ACT', true)
        }
        if (eventParse.type === 'contagion') {
          console.log('qwea2', eventParse.message)
          self.$store.commit('SET_CONTAGION', eventParse.message)
          self.$store.commit('SET_COVID_ALERT', true)
        }
      }

      this.connection.onopen = function(event) {
        console.log(event)
        console.log("Successfully connected to the echo websocket server...")
      }
    },
    handleClosePanel () {
      this.$store.commit('SET_PANEL_DETAIL_ACTIVE', false)
      this.$store.commit('SET_PANEL_DETAIL', {})
    },
    add(message) {
      let notification = {
        msg: message.message,
        type: message.type,
        color: ''
      }

      if (message.type === 'check_in') {
        notification.color = 'primary'
      }

      if (message.type === 'check_out') {
        notification.color = 'gray'
      }

      this.addNotification(notification)
    }
  }
}
</script>

<style lang="scss">
.cv-wrapper {
  min-height: calc(100vh - 48px);
  min-width: 100vw;
  box-sizing: border-box;
  padding: 30px 30px 30px 280px;  
}

.cv-office {
  &__graph{
    margin: 5px 0 20px;
    &__bar {
      width: 100%;
      height: 10px;
      border-radius: 30px;
      background-color: #9E9E9E;
      position: relative;
    }

    &__innerbar {
      position: absolute;
      top: 0;
      left: 0;
      height: 10px;
      border-radius: 30px;
      background-color: #46C089;
    }
  }
}

.cv-area {
  &__img {
    height: 155px;
    border-radius: 10px;
    background-color: #DADADA;
    background-size: cover;
    background-position: center;
  }
}
</style>